import { faShippingFast } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { ClearShoppingcart, FormatAmount } from "../../../actions";
import { StoreContext } from "../../../store";
// import CollectionsPage from "../../CollectionsPage";
//import Shoppingcart from "../../shoppingcart";

const PayWithPayPal = (props) => {

  const PayPalButton = window.paypal.Buttons.driver("react", {
    React,
    ReactDOM,
  });
  const { dispatch,Collections} = useContext(StoreContext);


  const { Shoppingcart } = props;
  const [isPaid, setIsPaid] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  const items =  Shoppingcart.items.map((item)=>{

    const {title,price} = Collections.find(i =>i.id == item.id)?.fields;

    return ({
      name: title,   //item.Item.ListingData.Title,
      unit_amount: { value:price, currency_code: "USD" },
      quantity: item.quantity,
      sku: item.id,
    });

  });
 // items.push({"name":"shipping","unit_amount":{"value":"5.00","currency_code":"USD"},"quantity":1,"sku":"shipping"})



  const _invoiceID =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const createOrder = (data, actions) => {

    const _total = props.Total;//(Shoppingcart.total()).toString();
    const _subtotal = props.Subtotal//.toString();
    const _shipping = props.Shipping//.toString();
    const _discount = props.Discount;

    //const _subtotal = (Shoppingcart.subtotal() -  Shoppingcart.discount()).toString();
    // const _shipping = (Shoppingcart.shipping()).toString();

// var x = {"purchase_units":[{"amount":{"value":8.75,"currency_code":"USD","breakdown":{"item_total":{"value":5,"currency_code":"USD"},"shipping":{"currency_code":"USD","value":5},"discount":{"currency_code":"USD","value":1.25}}},"invoice_id":"9gklp1sibweduxk1kn0sw","items":[{"name":"Vidar","unit_amount":{"value":"5.00","currency_code":"USD"},"quantity":1,"sku":"vidar111520"}]}]};

//    console.log('discountv' + JSON.stringify(x))


    return actions.order.create({
    
      purchase_units: [
        {
          amount: {
            value: _total,
            currency_code: "USD",
            breakdown: {
              item_total: { value: _subtotal, currency_code: "USD" },
              shipping: {
                currency_code: 'USD',
                value: _shipping
              },
              discount: {
                currency_code: 'USD',
                value: _discount
              },
            },
          },
          invoice_id: _invoiceID,
          items: items,
        },
      ]
    });
  };
  const onApprove = (data, actions) => {
    //return actions.order.capture();
    return actions.order.capture().then(function (details) {
      // This function shows a transaction success message to your buyer.
      console.log("Transaction completed by " + details.payer.name.given_name);
      setIsPaid(true);
      setName(details.payer.name.given_name);
      ClearShoppingcart(dispatch);
    });
  };

  const Successful = (
    <div
      style={{
        fontSize: "45",

        backgroundColor: "#e1d798",
        padding: "10px",
      }}
    >
      Thank you {name}! We very much appreciate your business. You will receive
      an email confirmation of this transaction.
    </div>
  );

  return (
    <div>
      {isPaid ? (
        Successful
      ) : (
        <PayPalButton
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
        />
      )}
    </div>
  );
};
export default PayWithPayPal;
