import React, { useContext, useEffect, useState } from "react";
import NoPhoto from "./NoPhoto";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { StoreContext } from "../../store";
import { useParams } from "react-router-dom";
import { UpdateShoppingcartItem ,GetProducts, LoadProductsFromLocalStorage} from "../../actions";
import DropDownQty from "./dropdown/DropDownQty";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import ActionButton from "./buttons/ActionButton";

const ProductDetail = (props) => {
  const {
    dispatch,
    AuthToken,
    Collections,
    Shoppingcart,
    IsLogin,
    BaseURL,
    AccountID,
    ImageLocation,
  } = useContext(StoreContext);

  const [hearts, setHearts] = useState(0);

//if Collections is empty due to refresh reload products
  useEffect(() => {
    if (Collections.length == 0) GetProducts(dispatch);
  }, [Collections]);



  let { id } = useParams();
  const currency = "$";



//don't do anything else if collections empty  
if(Collections.length==0)
{
  return <span>Loading</span>;
}


  const listing = Collections?.find((item) => item.id == id);
  const itemInCart = Shoppingcart.items.find((item) => item.id == id);
  const { title, description, images, price, quantity, ingredients, fragrance, likes, collectionname , size} = listing?.fields;

  function getReleaseDate(){
    const curedays = 28;
    const createdDate = Date.parse(listing.datecreated);
    let differenceInTime =  Date.now() - createdDate;
    return curedays-differenceInTime/(1000 * 3600 * 24);
  }

  const _ingredients = listing?.fields.ingredients;
  const imagesPath = images.map(function (item) {
    return {
      original: ImageLocation + item,
      thumbnail: ImageLocation + item,
    };
  });

  const renderListingDetailContent = () => {
    return (
      <div
      id="product_detail_container"
        style={{
          display: "flex",
          // flexDirection: "row",
          flexWrap:'wrap',
          padding: "10px",
          justifyContent: "center",
        }}
      >
        <div id="detail_left_box" >
          <div
            style={{
              height: "auto",
              maxWidth: "100%",
            }}
          >
            <div style={imageDivStyle}>
              {images.length <= 0 ? (
                <NoPhoto></NoPhoto>
              ) : (
                imagesPath.length > 1 ?                 
                <ImageGallery items={imagesPath} showPlayButton={false} showThumbnails={true}/>:
           <ImageGallery items={imagesPath} showPlayButton={false} showThumbnails={false}/>

             
              )}

              {/* <div>{IsLogin ? renderAdmin(product) : null}</div> */}
            </div>
          </div>
          {/* <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faHeart}
              color="#6ea171"
              size="lg"
              onClick={() => setHearts(hearts + 1)}
              data-tip={`${hearts} heart this item`}
            />
            {hearts}
          </div> */}
        </div>
        <div
          id="detail_right_box"
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            // width: "500px",
            backgroundColor: "#F8F8F8",
            minHeight: "300px",
          }}
        >
          <div>
          <NavLink
               as={Link}
               to={`../collections/${collectionname}`}
               activeClassName="active"
               style={{ textDecoration: "none" }}
             >
            <p style={{fontSize:'20px', fontStyle:'italic'}}>
            {collectionname}
          </p>
          </NavLink>
         
            <p
              style={{
                color: "#424756",
                fontSize: "25px",
                fontWeight:'bold'
              }}
            >
              {title}
            </p>
            <p>{description}</p>
            <p>Estimated Size: {size}</p>
          </div>
          <div>
            <span
              style={{
                color: "#424756",
                fontSize: "15px",
              }}
            >
              {currency}{price}
            </span>
          </div>
          
          <div style={{ flexGrow: "2" }}>
            <span style={{ fontSize: "12px" }}>
              { itemInCart == null || itemInCart.length < 1
                ? quantity > 0 ? "" :''
                : `You've added ${itemInCart.quantity} in your cart.`}
            </span>{" "}
            {(quantity <= 0 )? <span>Sorry, Out of Stock.</span>  :  (getReleaseDate()>=1)?<span style={{color:'#4d784e', fontSize:'20px'}}>Coming Soon!  Will be available in {getReleaseDate().toFixed(0)} day {(getReleaseDate().toFixed(0)>1)?'s':''} </span>:
            <DropDownQty
              Product={listing}
              Selected={itemInCart == null ? 0 : itemInCart.quantity}
              Action = {qtySelected}
            ></DropDownQty>}
          </div>
<br></br>
          <div style={{ flexGrow: 3 , fontWeight:'bold'}}  >
            {fragrance?'':            <p>
            No Harmful ingredients like Parabens, Phthalates, Formaldehyde, SLS and SLES
            </p>}

          </div>
          <div style={{ flexGrow: 3 }}>
            <p>We strive to only use natural and sustainable ingredients.  Each ingredient is purposely selected for its known benefits.</p>
            <table className="lightgray-border">
              <tbody>
              <tr><th className="lightgray-border">Ingredients</th><th className="lightgray-border">known for</th></tr>
            {_ingredients.map((item,index)=>{
              return(<tr className="lightgray-border" key={index}>
                  <td className="lightgray-border">{item.name}</td><td className="lightgray-border">{item.reason}</td>
              </tr>)

            })}
            </tbody>
            </table>
 
          </div>        
          <br></br>
          <div style={{flexGrow:3}} style={{fontSize:'12px', fontStyle:'italic'}}>
          *Do a patch test if using for the first time and discontinue use if irritation occurs.          
          <br></br>
          *Due to the nature of being handmade, size and looks may vary from bar to bar.
          <br></br>        
          *Keep in a dry place after each use to make it last.
          <br></br>        
          *We do not claim any benefits from the use of this product. This product is not intended to diagnose, treat, cure, or prevent any disease. 
          Please consult your doctor before using this product if you have any medical conditions.
          </div>
        </div>
      </div>
    );
  };

  const qtySelected = (selected)=>{
    UpdateShoppingcartItem(dispatch, Shoppingcart,listing.id, listing.fields.price, selected)
  }

  return (
    (Collections.length==0) ? <span>Loading</span> : (
    <div style={{ textAlign: "center" }}>      
  {renderListingDetailContent()}
  <br></br>
  <div className="_row">
    <div>
      <Link to="/collections" style={{ textDecoration: "none" }}>
        <ActionButton Label="Continue Shopping"></ActionButton>
      </Link>
    </div>
    <div>
      <Link to="/shoppingcart" style={{ textDecoration: "none" }}>
        <ActionButton Label="Shopping Cart"></ActionButton>
      </Link>
    </div>
  </div>
  {/* <ReactTooltip effect="solid" /> */}

 
</div>
   
))
}
export default ProductDetail;

const descriptionStyle = {
  borderTop: ".5px solid gray",
  borderRadius: "10px",
  display: "flex",
  textAlign: "left",
  minWidth: "100px",
  lineHeight: "1.5",
  letterSpacing: "1px",
  fontSize: "16px",
  padding: "10px",
};
const productContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#424756",
  padding: "20px",
  borderRadius: "10px",
  width: "100%",
  margin: "10px",
  color: "#e1d798",
};

const imageDivStyle = {
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
};

const inputStyle = {
  width: "300px",
  textAlign: "right",
  margin: "10px",
  // border: ".5px solid red",
};
