import React, { useContext, useEffect, useState } from "react";

const ActionButton = (props) => {
  const btnLabel = props.Label;

  const handleOnClick = props.Action;
  const style = props.Style?props.Style: defaultStyle;

  return (
    <div
      style={style}
      onClick={handleOnClick}
    >
      {btnLabel}
    </div>
  );
};

const defaultStyle ={
  textAlign: "center",
  color: "white",
  backgroundColor: "#6ea171",
  padding: "15px",
  borderRadius: "10px",
  minWidth: "150px",
  margin: "5px"}

export default ActionButton;
