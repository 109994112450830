import React from "react";
import ReactTooltip from 'react-tooltip';
import {
faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShippingGuide = () => {

    return(
    <React.Fragment><FontAwesomeIcon
        data-for="main"
        data-tip="Shipping Guide  (48 contiguous United States):<br />1 bar - $5<br /> 2 bars - $7 <br /> 3 bars - $9 <br /> 4 bars - $11 <br /> 5 bars - $13 <br /> 6-12 bars - $15 <br /> 13 and more bars - Free $hipping"
        data-iscapture="true"
        title="Shipping Guide"
        icon={faInfoCircle}
        color="Dodgerblue"
        size="sm"
      />
      <ReactTooltip
      id="main"
      place="right"
      type="dark"
      effect="float"
      multiline={true}
    /> </React.Fragment> );
}

export default ShippingGuide;