import React, { useState, useContext } from "react";
import { StoreContext } from "../../../store";

import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
//import "../../../../src/styles.less";
import ModalWrapper from "../modal/Modal";
import { SendMessage } from "../../../actions";
import { sheets } from "less";

//import { GetProducts, GetCategories } from "../actions";

const ShoppingCartIcon = (props) => {
  const { dispatch, AuthToken, Shoppingcart } = useContext(StoreContext);
  const list1 = {
    ListingID: 1000,
    ListingData: {
      ListingID: 1000,
      Title: "TestShoppingCart",
      Description: "TestShoppingCart",
      Cost: 50,
      Price: 100,
      Quantity: 1,
      Active: true,
      Images: [],
      CategoryID: 1,
      CategoryName: "",
      Condition: "New",
    },
    AccountID: "accountID",
    CreatedDate: null,
    ModifiedDate: null,
  };

  const cartContent = [
    { listingID: 1, quantity: 1 },
    { listingID: 2, quantity: 1 },
    { listingID: 2, quantity: 1 },
  ];


  return (
    <div>
      
      {(Shoppingcart?.items.length==0)?
      <Link to="/collections"><span style={{color:'#79a16d'}}>Your cart is empty. Start shopping!</span>
      <br></br>
      <FontAwesomeIcon icon={faShoppingCart} color="#6ea171" size="lg" />
      <span style={{ fontSize: "25px", color: "#4d784e", padding: "5px" }}>
        {Shoppingcart?.items.reduce((prev, cur) => prev + cur.quantity, 0)}
      </span>
      </Link>
      :
      <Link to="/shoppingcart"><span style={{color:'white'}}>View Cart</span>
            <br></br>
      <FontAwesomeIcon icon={faShoppingCart} color="#6ea171" size="lg" />
      <span style={{ fontSize: "25px", color: "#4d784e", padding: "5px" }}>
        {Shoppingcart?.items.reduce((prev, cur) => prev + cur.quantity, 0)}
      </span>
       </Link>}
      

     
    </div>
  );
};

export default ShoppingCartIcon;
