import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import CollectionWrapper from '../components/atoms/imagetext/MainImage';
import Products from "./products";
import NoPhoto from "./atoms/NoPhoto";
import CollectionCards from "./atoms/CollectionCards";
import PageHeader from "./atoms/texts/PageHeader";
import { GetProducts } from "../actions";



const CollectionsPage = (props)=>{

    const {
        dispatch,
        ImageLocation, 
        Collections,
        CollectionNames
      } = useContext(StoreContext);
    
    const imageURL =  "../../images/landingpage.jpg";
    const text = "";

    // useEffect(()=>{
    //     if(Collections.length==0)  GetProducts(dispatch)
    //   },[]);
    
    return (
        <div style={{marginBottom:'10px', textAlign:'center'}}>
            <p style={{margin:'10px', fontSize:'15px', color:'rgb(66, 71, 86)',display: 'inline-block', 'textAlign':'left', fontStyle:'italic',letterSpacing:'2px'}}>
            Started with a dream, made with love. Experience the luxury of natural ingredients with our handmade soaps.<br></br>
            </p>
            <PageHeader Text="Our Soaps Collections"></PageHeader>
            <div style={{textAlign:'center'}}>
            <p>
            {/* <ul style={{margin:'10px', display: 'inline-block', textAlign:'left', listStyle:'none'}}>
              <li>February Collection Will Be Available February 1st!</li>              
            </ul> */}
            </p>
           <p>How about a custom made bar of soaps just for you? Contact us, we'll craft it for you!</p>
            <p>
            We only make small batches of soaps, if you find the ones you want Out Of Stock, please do reach out and we'll be happy to make a batch for you. No, you don't need to buy the whole batch :)
            </p>
            </div>                          
            <CollectionCards  CollectionNames={CollectionNames}/>
        </div>
   
    )
}

export default CollectionsPage;