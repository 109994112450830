import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
// import CollectionWrapper from './atoms/imagetext/MainImage';
// import Products from "./products";
// import NoPhoto from "./atoms/NoPhoto";
//import CollectionCards from "./atoms/CollectionCards";
import { useParams } from "react-router-dom";
import DropDown from "./atoms/dropdown/DropDown";
import DropDownCheckBox from "./atoms/dropdown/DropDownCheckBox";
import PageHeader from "../components/atoms/texts/PageHeader";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import ContactUsModal from "../components/atoms/molecules/ContactUsModal";
//import ActionButton from "./buttons/ActionButton";
import ActionButton from "../components/atoms/buttons/ActionButton";
import { UpdateShoppingcartItem } from "../actions";
import { GetProducts } from "../actions";


const CollectionPage = (props)=>{

  const currency = "$";
  const { collectionname} = useParams();

  // useEffect(()=>{
  //   if(Collections.length==0)  GetProducts(dispatch)
  // },[]);

    const {
        SortOptions, 
        Collections,
        ImageLocation,
        Shoppingcart,
        dispatch
      } = useContext(StoreContext);
    const imageURL =  "../../images/landingpage.jpg";
    const text = "";

    const itemsInCart = Shoppingcart.items;

    const [showContactModal, setShowContactModal] = useState(false);
      
    const [sortBy, setSortBy] = useState(SortOptions[0])

    const [collections, setCollections] = useState(Collections?.filter((item)=>item.fields.collectionname==collectionname || collectionname=="All"));

    useEffect(()=>{
      if(Collections.length==0)  GetProducts(dispatch)
    },[]);
    
    useEffect(()=>{
      if(Collections.length>0) {
        setCollections(Collections?.filter((item)=>item.fields.collectionname==collectionname || collectionname=="All"))
      }
    },[Collections]);
    
    const sortIt = (list)=>{


      switch (sortBy) {
        case 'A-Z':
          return list.sort((a,b)=>(a.fields.title.localeCompare(b.title)))
          break;
        case 'Z-A':      
         return list.sort((a,b)=>(b.fields.title.localeCompare(a.title)));
          break;
        default:
          console.log(`Sorry,unknown ${sortBy}.`);
      }
    }

    function getReleaseDate(listing){
      const curedays = 28;
      const createdDate = Date.parse(listing.datecreated);
      let differenceInTime =  Date.now() - createdDate;
      return curedays-differenceInTime/(1000 * 3600 * 24);
    }

    const handleClickBuy = (item) => {
      let shoppingCartItem = itemsInCart.find((i) => i.id == item.id);

      //click buy from collection page always adds just 1 
      const qty = 1;

      UpdateShoppingcartItem(dispatch, Shoppingcart, item.id, item.fields.price, qty);
    };

    const onSelectSort = (item)=>{
      setSortBy(item);
    }
    
  const displayCollection = () =>{
    return(
              
    <div style={{ display:'flex',flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>

       {sortIt(collections)?.map((item,index)=>{
         const {images,title, price, quantity,collectionname} = item.fields;

        const itemInCart = itemsInCart.find((i) => i.id == item.id);

         return(
           <NavLink
           key={index}
           as={Link}
           to={`../productDetail/${item.id}`}
           activeClassName="active"
           style={{ textDecoration: "none" }}
         >
           <div style={{ display:'flex',flexDirection:'column', margin:'10px', backgroundColor:'#F5F5F5',minHeight:'450px', maxWidth:'400px', justifyContent:'space-between', border:'0px solid gray', padding:'10px'}}>
             <div style={{display:'flex', justifyContent:'center'}}>
                <Image   src={ImageLocation + images[0]}  style={{ width: "auto", height: "250px"}}/>   
              </div>
              <div>
                <span style={{top:'50%'}} style={{ fontFamily:'Roboto', fontStyle:'italic'}}>{collectionname}</span>
              </div>
              <div>
                <span style={{top:'50%'}} style={{ fontFamily:'Roboto'}}>{title}</span>
              </div>
              <div>
                <span style={{top:'50%'}}>{currency} {price}</span>
              </div>
              <div>
                  {quantity > 0 && getReleaseDate(item)<1 ? (
                    <NavLink
                      as={Link}
                      to={`../productDetail/${item.id}`}
                      activeClassName="active"
                      style={{ textDecoration: "none" }}
                    >
                      <ActionButton
                        Label={
                          itemInCart == undefined ? "Add to cart" : "Add 1 more to cart"
                        }
                       Action={() => handleClickBuy(item)}
                      ></ActionButton>
                    </NavLink>
                  ) : (
                   <ActionButton Label={quantity > 0?"Coming Soon!": "Out of Stock"}></ActionButton>
                  )}
              </div>
           </div>
           </NavLink>)
  })}
     

     </div>
    )
    }

    return (
    <div style={{marginBottom:'10px', textAlign:'center'}}>
      <PageHeader Text={collectionname}></PageHeader>
      <div style={{ border:'1px solid #DCDCDC', height:'auto', display:'flex'}}>
      <div style={{padding: '10px'}}>
          <DropDown Label="Sort By" Options={SortOptions} Action={onSelectSort}></DropDown>
       </div>
      </div>
    {collections.length > 0 ? displayCollection():<div style={{margin:'20px'}}><h3>This Collection is empty, check back in a later time or <span style={{fontStyle:'italic', color:'#019efc'}} onClick={() => setShowContactModal(!showContactModal)}>click here to drop us a message</span>. </h3></div>}      

    {showContactModal ? (
          <ContactUsModal
            show={showContactModal}
            closeModal={() => setShowContactModal(!showContactModal)}
          />
        ) : null}
        </div>
   
    )
}

export default CollectionPage;