import React, { useState, useContext, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { StoreContext } from "../store";
import "../App.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ContactUsModal from "./atoms/molecules/ContactUsModal";
import { UpdateLogin, Logout } from "../actions";
import Image from "react-bootstrap/Image";
// import ShoppingCartIcon from ".././components/atoms/molecules/ShoppingCartIcon"

let Navigation = (props) => {
  const { IsLogin, dispatch, WebsiteName } = useContext(StoreContext);
  const {Shoppingcart} = useContext(StoreContext);

  const [showContactModal, setShowContactModal] = useState(false);
  const handleLogout = () => {
    Logout(dispatch);
  };

  const hideModalClick = () => {
    setShowContactModal(false);
  };

  const handleShowContact = () => {
    setShowContactModal(true);
  };

  return (
    <div>
      <div className="login-div">
        {IsLogin ? (
          <span onClick={() => handleLogout()}>
            Logout
          </span>
        ) : (
          ""
        )}
      </div>

      <div className="nav-bar" style={{  'display':'flex', justifyContent:'center', alignItems:'center',  flexWrap:'wrap', backgroundColor:'#304f3f'}}>
        {/* <div>   
          <Image   src={require('../images/coconinjagreen.png')}  style={{ width: "75px", height: "75px",objectFit:'contain' }} /> 
        </div> 
        <div className="websiteName" style={{fontSize:'30px', color:'black', fontWeight:'bold', textAlign:'left'}}>      
        {WebsiteName}
        </div>*/}
        <div className="break"></div>
      <div  className="NavRow">
        <div className="navSpan">
          <NavLink
            as={Link}
            to="/home"
            activeClassName="active"
            style={{ textDecoration: "none", color:'white' }}
          >
            Home
          </NavLink>
        </div>
        <div className="navSpan">
          <NavLink
            as={Link}
            to="/collections"
            activeClassName="active"
            style={{ textDecoration: "none", color:'white' }}
          >
            Products
          </NavLink>
        </div>
        <div className="navSpan" onClick={() => handleShowContact()}>
          <a style={{whiteSpace:'nowrap', color:'white'}} >Contact Us</a>
        </div>
{/* 
        <div className="navSpan" style={{whiteSpace:'nowrap'}} onClick={() => handleShowContact()}>
        {(Shoppingcart.length==0)?<span style={{color:'white'}}>Your cart is empty. Start shopping!</span>:''}<ShoppingCartIcon></ShoppingCartIcon> 
        </div> */}

      </div>    
        {showContactModal ? (
          <ContactUsModal
            show={showContactModal}
            closeModal={() => hideModalClick()}
          />
        ) : null}
         {/* {IsLogin ? (
          <div className="navSpan" style= {{'flexGrow':'1' ,whiteSpace:'nowrap'}}>
            <NavLink
              as={Link}
              to="/productsadmin"
              activeClassName="active"
              style={{ textDecoration: "none" }}
            >
              Manage Page
            </NavLink>
          </div>
        ) : null} */}
            {/* <div style={{'flexGrow':'2', fontSize:'30px', color:'#555555'}}>      
      </div> */}
      </div>
    </div>
  );
};
export default Navigation;
