import React, { useContext, useEffect, useState } from "react";
import NoPhoto from "./NoPhoto";
import Image from "react-bootstrap/Image";
import { StoreContext } from "../../store";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import DeleteConfirmation from "./molecules/DeleteConfirmation";
import UploadModal from "./molecules/UploadModal";
// import Products from "../products";

const ProductCardAdmin = (props) => {
  const { BaseURL, AccountID, ImageLocation } = useContext(StoreContext);
  const currency = "$";
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  //props
  const [title, setTitle] = useState(props.Product.ListingData.Title);

  const product = props.Product;

  const accountInfo = props.Product.AccountInfo;

  //alert(JSON.stringify(props.children));

  const hideModalClick = () => {
    setShowContactModal(false);
  };

  // const renderAdmin = (product) => {
  //   alert("title:" + title);
  //   return (
  //     <React.Fragment>
  //       {" "}
  //       <div className="col-item">
  //         <h1>Products Admin</h1>
  //       </div>
  //       <div className="col-item">
  //         <DeleteConfirmation
  //           show={showConfirmDeleteModal}
  //           closeModal={() => hideModalClick()}
  //           Product={product}
  //           Msg={`Are you sure you want to delete this item (${title}) ?`}
  //         ></DeleteConfirmation>
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // const imageURL = product.ListingData.Images.filter(
  //   (file) => file.includes("jpg") || file.includes("png")
  // );
  return (
    <div style={productContainerStyle}>
      <div style={{ ...colItem, ...{ width: "50px" } }}>
        <span style={{ fontSize: "25px" }}>{props.Count + 1}</span>
      </div>
      <div>
        {product.ListingData.Images[0] == null ? (
          <NoPhoto></NoPhoto>
        ) : (
          <Image
            src={ImageLocation + product.ListingData.Images[0]}
            alt={product.ListingData.Title}
            style={{ width: "auto", height: "100px" }}
          />
        )}
      </div>
      <div style={colItem}>{product.ListingData.Title}</div>
      <div style={colItem}>
        <div style={{ textAlign: "left" }}>
          <UploadModal Product={product}>
            <div
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: "#4b5320",
                padding: "15px",
                borderRadius: "10px",
                width: "150px",
                margin: "5px",
              }}
            >
              Add Edit
            </div>
          </UploadModal>{" "}
        </div>
      </div>{" "}
      <div style={colItem}>
        <div style={{ textAlign: "left" }}>
          <DeleteConfirmation
            show={showConfirmDeleteModal}
            closeModal={() => hideModalClick()}
            Product={product}
            Msg={`Are you sure you want to delete this item (${title}) ?`}
          >
            <div
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: "#675645",
                padding: "15px",
                borderRadius: "10px",
                width: "150px",
                margin: "5px",
              }}
            >
              Delete
            </div>
          </DeleteConfirmation>{" "}
        </div>
      </div>
    </div>
  );
};

export default ProductCardAdmin;

const productContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "#424756",
  padding: "20px",
  borderRadius: "10px",
  width: "1000px",
  margin: "10px",
  flexWrap: "wrap",
  color: "#e1d798",
};

const colItem = {
  width: "200px",
  //border: ".5px solid gray",
  padding: "10px",
  display: "flex",
  alignItems: "center",
};

const colItemNum = {
  backgroundColor: "red",
};

const imageDivStyle = {
  justifyContent: "center",
  display: "flex",
};

const inputStyle = {
  width: "300px",
  textAlign: "right",
  margin: "10px",
  // border: ".5px solid red",
};
