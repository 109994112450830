import React, { useState, useContext, useEffect } from "react";

import { Dropdown } from "react-bootstrap";
import { UpdateShoppingcartItem } from "../../../actions";
import { StoreContext } from "../../../store";

const DropDownQty = (props) => {
  const [selected, setSelected] = useState(0);

  const { dispatch, Shoppingcart } = useContext(StoreContext);

  const _product = props.Product;

  const _selected = props.Selected;

  useEffect(() => {
    setSelected(props.Selected);
  }, [props.Selected]);

  const _options = [...Array(_product.fields.quantity)].map(
    (_, i) => i + 1
  );
  let _shoppingcartItem = Shoppingcart.items.find(
    (i) => i.id == _product.id
  );

  const handleOnClick = (qty) => {
    
     setSelected(qty);
     props.Action(qty);
  };
  
  
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          minWidth: "50px",
          textAlign: "right",
          backgroundColor: "#4d784e",
        }}
      >
        {selected}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
        {_options.map((item,index) => {
          return (
            <button
              className="dropdown-item"
              type="button"
              onClick={() => handleOnClick(item)}
              key={index}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default DropDownQty;
