import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../../store";
import {
  GetAPIVersion,
  UpdateLogin,
  Logout,
  SaveProductWithUpload,
  ActivityNotification
} from "../../../actions";
import { Link } from "react-router-dom";
import { faBold, faFlagUsa } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const { APIVersion, dispatch, IsLogin } = useContext(StoreContext);
  useEffect(()=>{
    ActivityNotification(dispatch)
  },[]);

  // useEffect(() => {
  //   GetAPIVersion(dispatch);

  //   // let product = {
  //   //   AccountInfo: { AccountID: "accountID1", AppID: 2 },
  //   //   Product: { Title: "title1", Description: "description1", Tags: [1, 2] },
  //   //   Files: [],
  //   // };

  //   // SaveProductWithUpload(product, dispatch);
  // }, []);

  const handleLogout = () => {
    Logout(dispatch);
  };

  const handleLogin = () => {
    //UpdateLogin(dispatch, true);
  };
  // useEffect(() => {
  //   console.log("is login" + IsLogin);
  // }, IsLogin);

  return (
    <div
      className="footer-container"
      style={{
        color: "#d8eaee",
        padding: "10px",
        backgroundColor: "#0e281d",
        marginBottom: "0px !important",
      }}
    >
      <div className="rectangle text-center">
        <p>
          <span style={{ fontFamily: "sans-serif" }}>
            GotCocos ©{new Date().getFullYear()}
          </span>
        </p>

        {/* <p>
          <Link to="/admin">
            <FontAwesomeIcon icon={faFlagUsa} color="#083B54" size="lg" />
          </Link>
        </p> */}

        <p className="pigeon-connect">
          Powered by{" "}
          <a href="mailto:charmedcoder@gmail.com"><span style={{color:'white'}}>Charmed Coder </span></a>
        </p>
      </div>
      <div className="counter-div"></div>
      <div className="api-version">
        {APIVersion == 1 ? "STATUS: API IS DOWN" : "API V" + APIVersion}- app
        V2.7
      </div>
      <br />
    </div>
  );
};
export default Footer;
