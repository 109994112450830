import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";

import { Link, NavLink } from "react-router-dom";

import { StoreContext } from "../store";

import Image from "react-bootstrap/Image";
import ImageModal from "./atoms/molecules/ImageModal";
//import Filter from "./atoms/molecules/filter";
import ContactUsModal from "./atoms/molecules/ContactUsModal";
import UploadModal from "./atoms/molecules/UploadModal";
import DeleteConfirmation from "./atoms/molecules/DeleteConfirmation";
import DropDownQty from "./atoms/dropdown/DropDownQty";
import NoPhoto from "./atoms/NoPhoto";
import ShoppingcartMobile from "./ShoppingcartMobile";
import ShoppingcartDesktop from "./ShoppingcartDesktop";
import DeviceIdentifier from 'react-device-identifier';
import {
  faMinusCircle,
  faPlusCircle,
  faTrash,
  faPlus,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActionButton from "./atoms/buttons/ActionButton";
import {
  GetProducts,
  GetCategories,
  UpdateShoppingcartItem,
  AddToCart,
  UpdateQuantityInCart,
  RemoveItemFromCart,
  FormatAmount,
  ApplyDiscountCode
} from "../actions";

// import ProductCard from "./atoms/delete_ProductCard";
//import DropDownListType from "./atoms/dropdown/DropDownListType";
import { GetShoppingcart } from "../actions";
import ShippingGuide from "./atoms/tooltip/ShippingGuide";

let ShoppingcartComponent = (props) => {
  //const baseUrl = "http://localhost:64567";
  const {
    dispatch,
    AuthToken,
    Collections,
    Shoppingcart,
    IsLogin,
    BaseURL,
    ImageLocation,
    Products
  } = useContext(StoreContext);


  //const [filter, setFilter] = useState("All");
  const [showContactModal, setShowContactModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [products, setProducts] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState(null);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState();
  const [applyCodeLabel,setApplyCodeLable] = useState("#ff9999");
  const [showCoupon,setShowCoupon] = useState(false);
  const currency = "$";
  const removeItem = (id) => {
    RemoveItemFromCart(dispatch, id, Shoppingcart)
  };

useEffect(()=>{

setCoupon(Shoppingcart.discountcode);
},[Shoppingcart.discountcode]);

  useEffect(() => {
    // console.log(JSON.stringify(Products));
    // if (Shoppingcart.length == 0) {
    setLoading(true);
    //temp fix this
    GetShoppingcart(dispatch);
    //  }
  }, []);

  const handleApplyDiscountCode = ()=>{

    ApplyDiscountCode(dispatch, Shoppingcart,coupon);
    setApplyCodeLable("#ffe6e6")

  }




  const renderDesktop = () => {
    return (
      <React.Fragment>
        <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: ".5px solid 	#e1d798",
            padding: "10px",
          }}
        >
        </div>
        <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: ".5px solid #e1d798",
            padding: "10px",
          }}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 250px",
            }}
          ></div>
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 300px",
            }}
          >
            Item
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 50px",
              textAlign: "right",
            }}
          >
            Price
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 100px",
              textAlign: "center",
            }}
          >
            Qty
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 50px",
              textAlign: "right",
            }}
          >
            Total
          </div>
        </div>

        {Shoppingcart.items.map((item,index) =>
        {
          const qtySelected = (selected)=>{
            UpdateShoppingcartItem(dispatch, Shoppingcart, item.id, item.price, selected);
          }

          var quantity = item.quantity;
          
         const shoppingcartItem = (Collections.find((p)=>p.id == item.id))?.fields;
         
          const {title,price,images} = shoppingcartItem;
         
          return (
        
          <div
            key={index}
            className="_row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              borderBottom: border_bottom,
              padding: "10px",
            }}
          >
            <div
              style={{
                flexGrow: "4",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flex: "0 0 250px",
              }}
            >
              {images[0] == null ? (
                <NoPhoto></NoPhoto>
              ) : (
                <Image
                  src={
                   ImageLocation +
                    images[0]
                  }
                  alt={title}
                  style={{ maxWidth: "200px", height: "auto" }}
                />
              )}

              {/* <div>{IsLogin ? renderAdmin(product) : null}</div> */}
            </div>
            <div
              style={{
                flexGrow: "4",
                flex: "0 0 300px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  height: "50%",
                }}
              >
                <NavLink
                  as={Link}
                  to={`productDetail/${item.id}`}
                  activeClassName="active"
                  style={{ textDecoration: "none" }}
                >
                  <span> {title}</span>{" "}
                </NavLink>
              </div>

              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => removeItem(item.id)}
                >
                  Remove Item
                </span>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {/* <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => alert("feature not yet available")}
                >
                  Save For Later
                </span> */}
              </div>
            </div>
            <div
              style={{
                flexGrow: "1",
                flex: "0 0 50px",
                textAlign: "right",
              }}
            >
              {currency} {price}
            </div>
            <div
              style={{
                flexGrow: "2",
                flex: "0 0 100px",
                textAlign: "center",
              }}
            >
              <DropDownQty
                Product={Collections.find(
                  (i) => i.id == item.id
                )}
                Selected={quantity}
                Action={qtySelected}
              ></DropDownQty>
            </div>

            <div
              style={{
                flexGrow: "2",
                flex: "0 0 50px",
                textAlign: "right",
              }}
            >
              {currency} {item.quantity * price}
            </div>
            {/* <div style={{ flexBasis: "100%", height: "0" }}></div>
              <div>
                Remove This Item
                <div style={{ color: "#675645" }}>
                  <FontAwesomeIcon
                    title="Remove Item"
                    icon={faTrash}
                    color="Tomato"
                    size="lg"
                    onClick={() => removeItem(item.Item.ListingID)}
                  />
                </div>
              </div> */}
          </div>
        )}
        )}
        {/* <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: ".5px solid grey",
            padding: "10px",
          }}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 250px",
              border: "1px solid red",
            }}
          >
            Your Total Before Taxes and Shipping:
          </div>
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 250px",
              border: "1px solid red",
            }}
          >
            {currency}
            {Shoppingcart.reduce((total_sum, a) => total_sum + a.Total, 0)}
          </div>
        </div>
         */}
        <div
          className="_row"
          style={rowStyle}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 600px",
            }}
          >
            Subtotal
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 150px",
              textAlign: "right",
            }}
          >
            {FormatAmount(Shoppingcart.subtotal())}{" "}
          </div>
        </div>
        {(Shoppingcart.discount()>0) ?
        <div
          className="_row"
          style={rowStyle}
        >
          
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 600px",
            }}
          >
            Discount
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 150px",
              textAlign: "right",
            }}
          >
             <span style={{color:'red'}}>- {FormatAmount(Shoppingcart.discount())}</span>{" "}
          </div>
        </div> 
        :''}       
        <div
          className="_row"
          style={rowStyle}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 600px",
            }}
          >
           Shipping <ShippingGuide/>
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 150px",
              textAlign: "right",
            }}
          >
            {FormatAmount(Shoppingcart.shipping())}{" "}
          </div>
        </div>
        <div
          className="_row"
          style={rowStyle}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 600px",
              fontWeight: "bold",
            }}
          >
           Total
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 150px",
              textAlign: "right",
              fontWeight: "bold",
            }}
          >
            {FormatAmount(Shoppingcart.total())}{" "}
          </div>
        </div>
        <div
          className="_row"
          style={rowStyle}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 600px",
              fontWeight: "bold",
            }}
          >
           Coupon Code
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 150px",
              textAlign: "right",
              fontWeight: "bold",
            }}
          >
                  <input
                    type="text"
                    id="couponcode"
                    placeholder="Coupon Code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    required
                  />
                  <ActionButton Label="Apply Code" Action={()=>handleApplyDiscountCode()} Style={
                    { padding: "15px",
                      borderRadius: "10px",
                      minWidth: "150px",
                      margin: "5px",
                      cursor:'pointer',
                      backgroundColor:applyCodeLabel,
                      textAlign:'center'
                    }
                  }></ActionButton>

          </div>
      
        </div>
       
        {!props.DisplayActionBtns?'':
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
          <div>
            <Link to="/collections" style={{ textDecoration: "none" }}>
              <ActionButton Label="Continue Shopping"></ActionButton>
            </Link>
          </div>

          <div>
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <ActionButton Label="Checkout"></ActionButton>
            </Link>
          </div>
        </div>}
      </React.Fragment>
    );
  };



  

  const renderMobile = () => {
 
    const pStyle={fontWeight:'bold'}
    
    const _total_item = Shoppingcart?.items.reduce((prev, cur) => prev + cur.quantity, 0)

    function addRemoveItem(id,price,qty){

      UpdateShoppingcartItem(dispatch, Shoppingcart, id, price, qty);

    }
  
    return (
      <React.Fragment>

          {!props.DisplayActionBtns?'':
          <div style={{display:'flex', flexDirection:'column'}}>
          <div>
            <Link to="/collections" style={{ textDecoration: "none" }}>
              <ActionButton Label="Continue Shopping"></ActionButton>
            </Link>
          </div>

          <div>
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <ActionButton Label="Checkout"></ActionButton>
            </Link>
          </div>
        </div>}

        <p style={pStyle}>Order Summary:</p>
        <p> You have {(_total_item>1)?_total_item + ' items' :  _total_item + ' item'} with subtotal of {FormatAmount(Shoppingcart.subtotal())}
        </p>
        {
          (Shoppingcart.discount() > 0) ?        
          <p style={pStyle}>You received discount of {FormatAmount(Shoppingcart.discount())}</p>:null
        }
        {(Shoppingcart.shipping()>0)?
        <p>Your shipping cost {FormatAmount(Shoppingcart.shipping())}</p>:
        <p style={pStyle}>Your shipping is FREE!
        </p>}
   
          <p style={{fontWeight:'bold', textDecoration:'underline'}}>Your Total is {FormatAmount(Shoppingcart.total())}</p>
        <div style={{background:'#675645', color:'white',height:'50px', alignItems:'center', justifyContent: 'center', display:'flex',    borderRadius: '30px', margin: '10px'}} onClick={()=>setShowCoupon(!showCoupon)}>{showCoupon?'Hide':'I have coupon'}</div>
        {showCoupon?
        <div id="coupon-field" style={{alignItems:'center', display:'flex', flexDirection:'column'}}>
          
          <input
                    type="text"
                    id="couponcode"
                    placeholder="Coupon Code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    required
                  />
                  <ActionButton Label="Apply Code" Action={()=>handleApplyDiscountCode()} Style={
                    { padding: "15px",
                      borderRadius: "10px",
                      width: "50%",
                      margin: "5px",
                      cursor:'pointer',
                      backgroundColor:applyCodeLabel,
                      textAlign:'center'
                    }
                  }></ActionButton>
                  <p>Discount will apply to your subtotal.</p>


        </div>:null
        }
        <div style={{background:'#675645', color:'white',height:'50px', alignItems:'center', justifyContent: 'center', display:'flex',    borderRadius: '30px', margin: '10px'}} onClick={()=>setShowDetail(!showDetail)}>{showDetail?'Hide Detail':'View Detail'}</div>
        {showDetail?
        <div id="cart-details" style={{marginTop:'5px'}}>
          {
          Shoppingcart.items.map((item,index)=>{
          
            const qtySelected = (selected)=>{
              UpdateShoppingcartItem(dispatch, Shoppingcart, item.id, item.price, selected);
            }
  
            var qty = item.quantity;
            
            const {title, images,quantity, price} = Collections.find((c)=>c.id===item.id).fields;
            
            return(
            <div id={index} style={{display:'flex', flexDirection:'column', alignItems: 'center', borderBottom:'1px solid #e1d798', marginBottom:'5px'}}>
                <div>{index + 1}-{title}</div>
                <div><span style={{fontStyle:'italic'}}>({FormatAmount(price)} * {qty}) </span> <span style={{fontWeight:'bold'}}>{FormatAmount(price * qty)}</span></div>
                <Image
                  src={
                   ImageLocation +
                    images[0]
                  }
                  alt={title}
                  style={{ maxWidth: "200px", height: "auto" , margin:'10px'}}
                />
                
               {(quantity-qty > 0)? "Available " + (quantity - qty) : "Out of Stock"}
              <br></br>
              <div style={{width:'100%', display:'flex', justifyContent:'space-between',flexWrap:'wrap'}}>
                <div style={{textAlign:'center', background:'#e1d798',  flexGrow:'1', color:'#424756',fontWeight:'bold',height:'50px',alignItems:'center', justifyContent: 'center', display:'flex', fontSize:'50px'}}>
                <FontAwesomeIcon
                    title="Remove Item"
                    icon={faMinus}
                    color="Tomato"
                    size="md"
                    onClick={() => (qty == 0 )? '' : addRemoveItem(item.id, item.price, qty - 1)}

                  />
                </div>
                <div style={{textAlign:'center', background:'#e1d798',  flexGrow:'1', color:'#424756',fontWeight:'bold',height:'50px',alignItems:'center', justifyContent: 'center', display:'flex', fontSize:'25px'}}>
                      {qty}
                </div>
                <div style={{textAlign:'center',background:'#e1d798', flexGrow:'1',color:'#424756', fontWeight:'bold',height:'50px',alignItems:'center', justifyContent: 'center', display:'flex',fontSize:'50px'}}>
                <FontAwesomeIcon
                    title="Add Item"
                    icon={faPlus}
                    color="Dodgerblue"
                    size="md"
                    //order is greater than stock alert
                    onClick={() =>  (quantity - qty == 0)? '': addRemoveItem(item.id, item.price, qty + 1)}
                   // onClick={() => UpdateShoppingcartItem(dispatch, Shoppingcart, item.id, item.price, quantity + 1)}
                  />
                </div>
                <div style={{flexBasis:'100%',height:'5px'}}></div>

                <div onClick={() => removeItem(item.id)}  style={{textAlign:'center',flexGrow:'2', color:'#424756',height:'50px',alignItems:'center', justifyContent: 'center', display:'flex'}}>
                  <span>Remove Item</span>
                  </div>

                {/* <DropDownQty
                Product={Collections.find(
                  (i) => i.id == item.id
                )}
                Selected={quantity}
                Action={qtySelected}
              ></DropDownQty>               */}
              </div>
            </div>)
          
              
          
          })

          
        } 
        </div>:null}        
      </React.Fragment>
    );
  };

  return (
    <div className="_table" style={{ padding: "5px" }}>
      {/* <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {IsLogin ? (
          <div style={{ textAlign: "left" }}>
            <UploadModal Text="Add Product"></UploadModal>{" "}
          </div>
        ) : null}
      </div> */}
      {loading ? (
        Shoppingcart?.items?.length ? (
          <div>
            <DeviceIdentifier isMobile={true} isTablet={true}>
              {renderMobile()}
            </DeviceIdentifier>
            <DeviceIdentifier isDesktop={true} >
              {renderDesktop()}
            </DeviceIdentifier>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              margin: "50px",
            }}
          >
            <span style={{ color: "#675645", fontWeight: "bolder" }}>
              Your Cart Is Empty
            </span>
          </div>
        )
      ) : (
        <div>
          <span>Loading...</span>
        </div>
      )}

      {/* <div className="_row">
        <div>
          <Link to="/collections" style={{ textDecoration: "none" }}>
            <ActionButton Label="Continue Shopping"></ActionButton>
          </Link>
        </div>
        <div>
          <Link to="/checkout" style={{ textDecoration: "none" }}>
            <ActionButton Label="Checkout"></ActionButton>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default ShoppingcartComponent;

const border_bottom = ".5px solid #e1d798";
const rowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  borderBottom: { border_bottom },
  padding: "10px",
}