import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import NoPhoto from "./atoms/NoPhoto";
import Image from "react-bootstrap/Image";
import ReactTooltip from 'react-tooltip';
import {FormatAmount} from '../actions';
import { StoreContext } from "../store";
import PayWithPayPal from "./atoms/molecules/PayWithPaypal";
import ShippingGuide from "./atoms/tooltip/ShippingGuide";
import ShoppingcartComponent from "./ShoppingcartComponent";
import PageHeader from "./atoms/texts/PageHeader";

const ShoppingCartPage = () => {
  
  //checkout return
  return (
  <React.Fragment>
                {/* <h2 style={{ color: "#675645" }}>Your Shopping Cart</h2> */}
                <PageHeader Text="Your Shopping Cart"></PageHeader>

                <ShoppingcartComponent DisplayActionBtns={true}> </ShoppingcartComponent>
    </React.Fragment>)
};
export default ShoppingCartPage;
