("use strict");
import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/navigation";
import Home from "./components/home";
import Products from "./components/products";
import Admin from "./components/admin";
import Image from "react-bootstrap/Image";
import StoreProvider from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
// import FlexView from "react-flexview/lib";
import Footer from "./components/atoms/molecules/Footer";
import Filter from "./components/atoms/molecules/filter";
import ProductDetail from "./components/atoms/ProductDetail";
import ProductsAdmin from "./components/productsadmin";
import ShoppingCartPage from "./components/shoppingcart";
import CheckoutPage from "./components/checkout";
import CollectionsPage from "./components/CollectionsPage"
import CollectionPage from "./components/CollectionPage"
import ShoppingCartIcon from "./components/atoms/molecules/ShoppingCartIcon";
import { StoreContext } from "../src/store";
//import { GetProducts } from "../src/actions";


function App() {
  const {Shoppingcart} = useContext(StoreContext);
// alert('load app.js')

//   useEffect(()=>{
//     alert(dispatch)
//     GetProducts(dispatch)
//   },[]);



  const headerStyle = {
    color: "red",
    textAlign: "center",
    margin: "20px",
    fontFamily: "Impact, Haettenschweiler,Arial Narrow Bold, sans-serif",
    letterSpacing: "5px",
  };

  const [filter, setFilter] = useState("All");
  //const [emptycart, setEmptycart] = useState(true);
  const selectFilter = (event) => {
    setFilter(event.value);
  };

//   useEffect(()=>{
// alert('in effect')
//     if(Shoppingcart.length>0) setEmptycart(false)
//     else setEmptycart(true);
//   },Shoppingcart);




  return (
    <StoreProvider>
      <div className="main" style={{width:'1000px'}}>
        <Router>
          {/* <FlexView hAlignContent="center">
            <div className="logo">
              <h1>GotCocos</h1>
            </div>
          </FlexView> */}
          <div>
            <div style={{backgroundColor:'white', textAlign:'right',marginBottom:'0px'}}>           
        <div className="websiteName" 
        style={{fontSize:'30px', color:'black', fontWeight:'bold', textAlign:'left',  display:'flex', justifyContent:'center', alignItems: 'stretch'}}>      
            <div>
              <Image   src={require('./images/coconinjagreen.png')}  style={{ width: "75px", height: "75px"}} />
            </div>
            <div style={{flexGrow:'2', color:'#79a16d'}}>GotCocos</div>
            <div style={{fontFamily:'sans-serif', fontSize:'15px', fontWeight:'normal'}}>        
              <ShoppingCartIcon></ShoppingCartIcon>
              </div>        
        </div>
          </div>
            {/* <div style={{ backgroundColor: "black", padding: "10px" }}>
              <Filter
                FilterSelected={selectFilter}
                SelectedValue={filter}
              ></Filter>
            </div> */}
        
          <div style={{width:'100%'}}>
              <Navigation></Navigation>
            </div>
          </div>

          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            {/* <Route path="/products" exact component={Products} /> */}
            <Route path="/collections/:collectionname" exact component={CollectionPage} />
            <Route path="/shoppingcart" exact component={ShoppingCartPage} />
            <Route path="/checkout" exact component={CheckoutPage} />
            <Route path="/collections" exact component={CollectionsPage} />
            <Route path="/productDetail/:id" exact component={ProductDetail} />
            <Route path="/productsadmin" component={ProductsAdmin} />
            <Route path="/admin" component={Admin} />
          </Switch>
          <Footer></Footer>
        </Router>
      </div>
    </StoreProvider>
  );
}

export default App;
