import React, { useState, useContext, useEffect } from "react";
// impsort "bootstrap/dist/css/bootstrap.min.css";
import { StoreContext } from "../store";
import Image from "react-bootstrap/Image";
import Products from "./products";
import MainImage from '../components/atoms/imagetext/MainImage';
import CollectionCards from "./atoms/CollectionCards";
import { GetProducts } from "../actions";
import background from "../images/homebackground.mp4";


let Home = (props) => {

  const {
    dispatch,
    CollectionNames,
    Products
  } = useContext(StoreContext);

  const [collections,setCollections] = useState(null);

  useEffect(()=>{
    GetProducts(dispatch)
  },[]);



  return (


    <div className="center" style={{backgroundColor:'#0e281d', display:'flex',flexDirection:'column', textAlign:'center', maxHeight:'1500px', position:'relative'}} >
    


      <video autoPlay muted src={background} style={{width:'100%',height:'auto',overflow:'hidden', objectFit:'cover'}} />

    <div style={{margin:'20px', position:'absolute', top:'400px', color:'white'}}>
      <h2>
      Natural Soap for Dreams Fueled by Persistence and Determination</h2>
<br>
</br>
<br></br>
    <p style={{margin:'20px',fontSize:'20px', color:'white','textAlign':'left',fontFamily:'Roboto',letterSpacing:'2px'}}>
    At GotCocos, we believe that skincare should be a delightful experience—one that nourishes both your body and soul. Our handcrafted soaps are meticulously crafted with love and care, using the finest ingredients nature has to offer.
{/* Our mission is to promote natural products that are born out of love for people and environment. To Kickstart this mission
we're offering handcrafted soaps made with love and simple and sustainable ingredients.
<br></br><br></br>
{/* If you've experienced harshness from your bar of soaps it's time to try Natural Soaps.  Our Natural Soaps have no harsh chemicals added like parabens(linked to cancer), SLS and SLES (skin irritants), etc.
Instead of the bad stuff, it has natural glycerin that is nourishing to your skin plus the added benefits from some  amazing natural oils like shea butter, cocoa butter, olive oil, coconut oil, etc.  */}
{/* We hope that you support our mission and by using natural handmade soaps, we both can play a part on saving the environment and be rewarded with better skin. */}
{/* 
We care deeply about the environment so we strive that our ingredients are sourced responsibly and our soap making process is eco-friendly. We stay away from some ingredients like palm oil that is responsible for deforestation.
Our soaps are made in small batches that allow us to have minimal carbon foot print unlike big commercial soap companies.
</p>
<p style={{fontSize:'15px', color:'gray','textAlign':'center', fontStyle:'italic',letterSpacing:'2px'}}>
More importantly we care about you! Natural soaps are gentler and nourishing to your skin.
They don't contain harsh chemicals that could be absorbed by your skin (the body's largest organ). We make our 
soaps using simple yet beneficial ingredients like olive oil, coconut oil, shea butter, cocoa butter, clay, himalayan
salt, and hibiscus.
   */}
    </p>  
    {/* <p style={{fontSize:'20px', color:'gray','textAlign':'center', fontWeight:'bold'}}>More soaps coming out for February and March! Send us a message to get notified when new soaps become available.</p> */}

    <div style={{marginTop:'50px'}}>
      <CollectionCards Title="Check out our collections" CollectionNames={CollectionNames}/>
    </div>
    </div>
  </div>
  );
};

export default Home;
