import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from "../../store";
import Image from "react-bootstrap/Image";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import ActionButton from "./buttons/ActionButton";
import { UpdateShoppingcartItem , } from "../../actions";


const CollectionCards= (props)=>{

//get collectionnames
//loop thru collection to find a match for each collectionname and grab the image

  const {Title, CollectionNames, ShoppingCart, dispatch,Collections} = props;

    const {
        ImageLocation, 
      
      } = useContext(StoreContext);
    
      
  const displayCards = () =>{

    return(
              
    <div  style={{ display:'flex',flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>    
       {CollectionNames?.map((item,index)=>(
               <NavLink
               key={index}
               as={Link}
               to={`collections/${item.name}`}
               activeClassName="active"
               style={{ textDecoration: "none", display:'flex' }}
             >
           <div  style={{ display:'flex',alignItems:'center', flexDirection:'column', margin:'10px', padding:'10px', borderRadius:'10px',backgroundColor:'white',width:'250px', height:'250px', }}>
             <Image   src={item.image}  style={{ width: "90%", height: "90%",objectFit:'contain' }} /> 
             <span style={{color:'#79a16d'}}>{item.name}</span>
           </div>
                        
           </NavLink>
     ))}
     

     </div>
    )
    }

    return (
    <div style={{marginBottom:'10px'}}>
        <h2>{Title}</h2>
        {displayCards()}
        </div>
   
    )
}

export default CollectionCards;