import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import NoPhoto from "./atoms/NoPhoto";
import Image from "react-bootstrap/Image";
import ReactTooltip from 'react-tooltip';
import {FormatAmount} from '../actions';
import { StoreContext } from "../store";
import PayWithPayPal from "./atoms/molecules/PayWithPaypal";
import ShippingGuide from "./atoms/tooltip/ShippingGuide";
import ShoppingcartComponent from "./ShoppingcartComponent";

const CheckoutPage = () => {
  

  const {
    Currency,
    dispatch,
    AuthToken,
    Collections,
    Shoppingcart,
    IsLogin,
    ImageLocation,
  } = useContext(StoreContext);

  //const [total, setTotal] = useState(0);
 //const _total = Shoppingcart.items.reduce((total_sum, a) => total_sum + (a.price * a.quantity), 0);


 const renderOrderSummaryItems = ()=> (


  Shoppingcart.items.map((item) => {

    const shoppingcartItem = (Collections.find((p)=>p.id == item.id))?.fields;
         
    const {title,price,images} = shoppingcartItem;

return (
    <div
      className="_row"
      style={{        
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        borderBottom: border_bottom,
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flex: "0 0 150px",
        }}
      >
        {images[0] == null ? (
          <NoPhoto></NoPhoto>
        ) : (
          <Image
            src={
              ImageLocation +
             images[0]
            }
            alt={title}
            style={{ maxWidth: "100px", height: "auto" }}
          />
        )}

        {/* <div>{IsLogin ? renderAdmin(product) : null}</div> */}
      </div>
      <div
        style={{
          flex: "0 0 300px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            height: "50%",
          }}
        >
          <span> {title}</span>{" "}
        </div>
      </div>
      <div
        style={{
          flexGrow: "1",
          flex: "0 0 50px",
          textAlign: "right",
        }}
      >
        {Currency} {price}
      </div>
      <div
        style={{
          flexGrow: "2",
          flex: "0 0 100px",
          textAlign: "center",
        }}
      >
        {item.quantity}
      </div>

      <div
        style={{
          flexGrow: "2",
          flex: "0 0 50px",
          textAlign: "right",
        }}
      >
        {Currency} {item.price * item.quantity}
      </div>
    </div>
  )})

 )


  const OrderSummary = () => (
    <div
      style={{
        backgroundColor:'red',
        border: "1px solid #e1d798",
        borderRadius: "10px",
        padding: "10px",
        width: "100%",
        maxWidth: "800px",
      }}
    >
        <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: ".5px solid 	#e1d798",
            padding: "10px",
          }}
        >
          <div
            style={{
              flexGrow: "4",
              flex: "0 0 800px",
              textAlign: "center",
            }}
          >
            <h2 style={{ color: "#675645" }}>Order Summary</h2>
          </div>
        </div>
        <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: ".5px solid #e1d798",
            padding: "10px",
          }}
        >
          <div
            style={{
              flex: "0 0 250px",
            }}
          ></div>
          <div
            style={{
              flex: "0 0 200px",
            }}
          >
            Item
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 50px",
              textAlign: "right",
            }}
          >
            Price
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 100px",
              textAlign: "center",
            }}
          >
            Qty
          </div>
          <div
            style={{
              flexGrow: "1",
              flex: "0 0 50px",
              textAlign: "right",
            }}
          >
            Total
          </div>
        </div>
            {renderOrderSummaryItems()}
        <div
          className="_row"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderBottom: { border_bottom },
            padding: "10px",
            // border:'1px solid red'
          }}
        >
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div
              >
              Subtotal
            </div>
            <div
        
            >
              {/* {Shoppingcart.items.reduce((total_sum, a) => total_sum + (a.price*a.quantity), 0)}{" "} */}
              {FormatAmount(Shoppingcart.subtotal())}
            </div>          
          </div>
         {Shoppingcart.discount()>0 ?
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                  <div
                    >
                    Discount
                  </div>
                  <div
              
                  >
                    {/* {Shoppingcart.items.reduce((total_sum, a) => total_sum + (a.price*a.quantity), 0)}{" "} */}
                    <span style={{color:'red'}}>-{FormatAmount(Shoppingcart.discount())}</span>
                  </div>          
                </div>:'' 
        }


          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div>
            <a
              data-for="main"
              data-tip="Shipping Guide  (48 contiguous United States):<br />1-2 bars - $5<br /> 3 bars - $7 <br /> 4 bars - $9 <br /> 5 bars - $11 <br /> 6 bars - $13 <br /> 7-12 bars - $15 <br /> 13 and more bars - Free $hipping"
              data-iscapture="true"
            >Shipping  <ShippingGuide/>
            </a>
            </div>
                    <div>
              {FormatAmount(Shoppingcart.shipping())}{" "}
            </div>   
                   
          </div>
          <div style={{display:'flex',justifyContent:'space-between', fontWeight:'bolder',  borderTop:".5px solid 	#e1d798"}}>
            <div>
              Total
            </div>
            <div>
              {FormatAmount(Shoppingcart.total())}{" "}
            </div>   
                   
          </div>
        </div>
    </div>
  );


  //checkout return
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
                      <h2 style={{ color: "#675645" }}>Checkout</h2>

      <div
        style={{
          justifyContent: "center",
          padding: "10px",
        }}
      >

        {/* {OrderSummary()} */}
        <ShoppingcartComponent DisplayActionBtns={false}></ShoppingcartComponent>
      </div>
      <div style={{ padding: "10px",}}>
        <PayWithPayPal
          Shoppingcart={Shoppingcart}
          Total = {Shoppingcart.total()}
          Subtotal = {Shoppingcart.subtotal()}
          Shipping = {Shoppingcart.shipping()}
          Discount = {Shoppingcart.discount()}
        ></PayWithPayPal>
      </div>
    </div>
  );
};
const border_bottom = ".5px solid #e1d798";

export default CheckoutPage;
