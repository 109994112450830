import React, { createContext, useReducer } from "react";
import ActionTypes from "./actionTypes";

const initializedListing = {
  ListingID: 0,
  ListingData: {
    ListingID: 0,
    Title: "",
    Description: "",
    Cost: 0,
    Price: 0,
    Quantity: 0,
    Active: true,
    Images: [],
    CategoryID: 1,
    CategoryName: "",
    Condition: "New",
  },
  AccountID: "accountID",
  CreatedDate: null,
  ModifiedDate: null,
};
const url = "https://gotcocos-accountid-uploads.s3.amazonaws.com/";

const collectionnames = [
{name:'All', image:url + "label.png"},
//{name:'Musky Scented Collection', image:url + "sandbar.jpeg"},
// {name:'Unscented', image:url + "eliza.png"},
// {name:'Fall Collection', image:url + "FallInLove.jpeg"},
// {name:'Holiday Collection', image:url + "greenred.jpeg"},
{name:'February Collection', image:url + "feb24_1.jpg"},
{name:'March Collection', image:url + "march24.jpg"},
{name:'April Collection', image:url + "apr24_1.jpeg"},
// {name:'April Collection', image:url + "april24.jpg"},
// {name:'May Collection', image:url + "may24.jpg"},
];

const shoppingCart = {
  items:[],
  discountcode:'',
  subtotal:function(){ 
    const _total =  this.items.reduce((total_sum, a) => total_sum + (a.price*a.quantity), 0);  
    return _total; //- (_total * this.discount());
  },
  total:function(){
      return (this.subtotal()-this.discount()) + this.shipping();
    },
  shipping:function(){ 
    let shippingCost = 5;
    const qty = this.items.reduce((total_qty, a) => total_qty + a.quantity, 0);

    switch(qty){
      case 1:
        shippingCost=5;
        break;
        case 2:
          shippingCost=7;
          break;
        case 3:
          shippingCost=9;
          break;        
          case 4:
          shippingCost=11;
          break;
          case 5:
            shippingCost=13;
            break;        
          case 6: 
          case 7:
          case 8:
          case 9:
          case 10:      
          case 11:
          case 12:                                            
            shippingCost=15;
            break; 
          default:  
          shippingCost=0;
          break;   
    
    }

    return shippingCost;
  },  

  getDiscountRate:function(){
    let discount = 0;
    switch(this.discountcode){
      case 'love10off':
        discount=.10;
        break;
      default:  
        discount=0;
        break;   
    
    }

    return discount;

  },
  discount:function(){

    const _total = this.subtotal();
    return (_total * this.getDiscountRate());

  }
}

export const initialState = {
  Currency: "$",
  Categories: [],
  Products: [],
  NewProduct: initializedListing,
  IsLogin: false,
  UserName: "",
  APIVersion: 0,
  AccountID: "accountID1",
  CollectionNames:collectionnames,
  Collections: [],
  LS_Collections: JSON.parse(localStorage.getItem('ls_Collections')),
  SortOptions: ['A-Z', 'Z-A'],
  Shoppingcart:shoppingCart,
  WebsiteName:"Got Cocos",
  // BaseURL: "http://ec2-54-165-101-207.compute-1.amazonaws.com",
  //BaseURL: "http://localhost:50651/",
  ImageLocation: "https://gotcocos-accountid-uploads.s3.amazonaws.com/",
};

function reducer(state, action) {
  switch (action.actionType) {
    case ActionTypes.LOAD_CATEGORIES:
      return {
        ...state,
        Categories: [...new Set(action.payload)],
      };
    case ActionTypes.LOAD_PRODUCTS:
      return {
        ...state,
        Collections:action.payload// [...new Set(action.payload)],
      };
    case ActionTypes.GET_API_VERSION:
      return {
        ...state,
        APIVersion: action.payload,
      };
    case ActionTypes.RESET_RACE:
      return {
        ...state,
        Race: action.payload,
      };
    //temp
    case ActionTypes.LOAD_SHOPPINGCART:
      return {
        ...state,
        Shoppingcart: state.Shoppingcart,
      };
    case ActionTypes.UPDATE_SHOPPINGCART_ITEM:
      return {
        ...state,
        Shoppingcart: action.payload,
      };
    case ActionTypes.ADD_TO_CART:
      return {
        ...state,
        Shoppingcart: [...new Set(action.payload)],
      };

    case ActionTypes.UPDATE_PRODUCT_QUANTITY:
      return {
        ...state,
        Products: action.payload,
      };
    case ActionTypes.UPDATE_SHOPPINGCART:
      return {
        ...state,
        Shoppingcart: action.payload,
      };

    case ActionTypes.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        AuthToken: action.payload,
      };
    case ActionTypes.UPDATE_LOGIN:
      return {
        ...state,
        IsLogin: action.payload,
      };
    case ActionTypes.UPDATE_USERNAME:
      return {
        ...state,
        UserName: action.payload,
      };
  }
}

export const StoreContext = createContext({
  ...initialState,
  dispatch: undefined,
});

const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ ...state, dispatch: dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
